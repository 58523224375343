
import { Fade } from "react-awesome-reveal";
import { TbWorldWww } from "react-icons/tb";
// Fade
import { MdOutlineImportantDevices } from "react-icons/md";

import { CiMobile4 } from "react-icons/ci";
export default function Service() {
    return (
        <div className="grid lg:grid-cols-3 lg:gap-3  md:grid-cols-2 md:gap-2  sm:grid-cols-1 md:gap-1">
            <Fade direction="left" triggerOnce={true}>
            <div className=" bg-white shadow-2xl rounded-2xl p-2 flex flex-col justify-center mx-10 my-10 p-[2.5rem]">
              <div className="purple-bg-icon   w-[100px] box">
                <i className=" fi-icon  ">
                  <TbWorldWww className=" " size={40} color="#4d41e1" />
                </i>
              </div>
              <h2 className="text-2xl font-medium py-5">
                Elevating Your Web Experience
              </h2>
              <p className="text-lg font-light py-5 text-slate-400">
                Venture into the digital world with my custom web development
                services. I design responsive and intuitive websites that are
                tailored to your specific requirements, delivering a smooth and
                captivating online journey for your visitors
              </p>
            </div>
            </Fade>
            <Fade damping={0.1} direction="up" triggerOnce={true}>
            
            <div className=" bg-white shadow-2xl rounded-2xl p-2 flex flex-col justify-center mx-10 my-10 p-[2.5rem]">
              <div className="purple-bg-icon   w-[100px] box">
                <i className=" fi-icon  ">
                  <CiMobile4  className=" " size={40} color="#4d41e1" />
                </i>
              </div>
              <h2 className="text-2xl font-medium py-5">
                Innovative Mobile Solutions for Startups & Business{" "}
              </h2>
              <p className="text-lg font-light py-5 text-slate-400">
                Unlock the potential of mobile innovation with my advanced app
                development solutions. I focus on crafting intuitive and
                feature-packed mobile applications designed to connect with your
                audience and propel your business forward
              </p>
            </div>
            </Fade>
            <Fade direction="right" damping={0.1} triggerOnce={true}>
            <div className=" bg-white shadow-2xl rounded-2xl p-2 flex flex-col justify-center mx-10 my-10 p-[2.5rem]">
              <div className="purple-bg-icon   w-[100px] box">
                <i className=" fi-icon  ">
                  <MdOutlineImportantDevices className=" " size={40} color="#4d41e1" />
                </i>
              </div>
              <h2 className="text-2xl font-medium py-5">
                Rapid & Scalable Solutions with NOCode
              </h2>
              <p className="text-lg font-light py-5 text-slate-400">
                Seeking swift and effective solutions? My NOCode development
                services facilitate speedy prototyping and deployment, enabling
                you to realize your concepts more efficiently and affordably,
                all while maintaining high-quality standards
              </p>
            </div>
            
          </Fade>
          </div>
    );
}