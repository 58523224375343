import "./home.css";
import diamond from "../../assets/dimond.svg";
import bannerDots from "../../assets/banner-dots.svg";
import bannerDots2 from "../../assets/banner-dots-2.svg";
import curlyShape from "../../assets/curly-shape.svg";
import { AiOutlineMessage } from "react-icons/ai";
import { IoMdHappy } from "react-icons/io";
import { MdWorkspacePremium } from "react-icons/md";
import { MdGroups } from "react-icons/md";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { MdOutlineAltRoute } from "react-icons/md";

import Footer from "../commons/footer";
import Navbar from "../commons/navbar";
import { CarouselWithContent } from "./hero-component";
import Projects from "./components/projects";
import Testimonials from "./components/testimonials";
import { Fade } from "react-awesome-reveal";
import Service from "./components/service";
import { Helmet } from "react-helmet";

import { useState, useEffect } from "react";
import { useNavigate, } from 'react-router-dom';
const samplePng = require("../../assets/devImage.jpg");

export default function Home() {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
    <title>Karan N Patel | Expert Mobile & Web Development Services</title>
    <meta name="description" content="Specializing in mobile and web development, I offer tailored digital solutions to help clients achieve their business goals. Contact me today!" />
    <meta
          name="keywords"
          content="Mobile Development, Web Development, No-Code Solutions, Digital Solutions, Karan N Patel, Ecommerce Solution"
        />
  </Helmet>
      <Navbar title="Home" />

      <div className="main  sm:bg-hero-pattern bg-cover bg-center bg-primaryBgColor text-center ">
        <center>
          <div className="md:w-3/4 sm:px-5 xs:w-screen  h-screen flex justify-center items-center ">
            <CarouselWithContent />
          </div>
        </center>

        <div className="banner-shapes">
          <img
            src={diamond}
            alt="banner-diamond-shape"
            className="banner-diamond-shape"
          ></img>
          <img
            src={bannerDots}
            alt="banner-diamond-shape"
            className="banner-square-dots-1"
          ></img>
          <img
            src={bannerDots2}
            alt="banner-diamond-shape"
            className="banner-square-dots-2"
          ></img>
          <img
            src={curlyShape}
            alt="banner-diamond-shape"
            className="banner-curly-shape"
          ></img>
        </div>
      </div>
      <div className="sm:bg-circle-pattern bg-no-repeat bg-right-bottom w-screen justify-center items-center flex py-[90px]">
        <div className=" justify-center items-center flex flex-col md:px-[150px] ">
          <Fade cascade damping={0.1} direction="up">
            <h2 className="react-reveal mb-50 text-center sm:text-3xl sm:line-height-9 md:mb-20 text-3xl p-8 ">
              Embarking on this entrepreneurial journey, I'm dedicated to
              building transformative mobile/web apps that help startups thrive
              and achieve
              <span className="text-primaryBgColor"> their business goals</span>
            </h2>
          </Fade>
          <Service/>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 md:grid-cols-1 lg:gap-2   px-[40px]">
        <Fade cascade damping={0.2} direction="left" triggerOnce={true}>
        <div className="relative pr-[70px]">
          <img
            src={String(samplePng)}
            className="w-[100%] h-auto rounded-[5px]"
          />
          <div className="">
            <a
              className="support-mail absolute"
              href="mailto:karannpatel2329@gmail.com"
            >
              karannpatel2329@gmail.com
            </a>
          </div>
          <div className="features-white-dots"></div>
          <div className="features-color-dots"></div>
        </div>
        </Fade>

        <div className="flex flex-col md:px-20 sm:px-10 justify-center items-start relative">
          <Fade direction="up">
          <h2 className="text-3xl font-medium py-10">
            An agile developer adapting to your unique needs
          </h2>
          </Fade>
          <Fade direction="up">
          <p className="text-xl font-light text-slate-400">
            In the ever-changing digital world, I prioritize understanding your
            specific requirements. Embracing flexibility and open communication,
            I adapt quickly to evolving project needs. Partner with me for
            tailored solutions that exceed your expectations.
          </p>
          </Fade>
          <div>
            <Fade direction="up">
            <div className="grid md:grid-cols-2 grid-cols-1">
              <div className="flex items-center p-5 sm:my-5 sm:p-2 ">
                <div className="purple-bg-icon   w-[50px] box">
                  <i className=" fi-icon  ">
                    <MdOutlineAltRoute className=" " size={40} color="#4d41e1" />
                  </i>
                </div>
                <h3 className="text-lg font-medium">Adaptive Approach</h3>
              </div>
              <div className="flex items-center p-5 my-5 sm:p-2">
                <div className="purple-bg-icon   w-[50px] box">
                  <i className=" fi-icon  ">
                    <MdGroups className=" " size={40} color="#4d41e1" />
                  </i>
                </div>
                <h3 className="text-lg font-medium">Collaborative Spirit</h3>
              </div>
            </div>
            </Fade>
            <Fade direction="up">
            <div className="grid md:grid-cols-2 grid-cols-1">
              <div className="flex items-center p-5 my-5 sm:p-2">
                <div className="purple-bg-icon   w-[50px] box">
                  <i className=" fi-icon  ">
                    <LiaBusinessTimeSolid className=" " size={40} color="#4d41e1" />
                  </i>
                </div>
                <h3 className="text-lg font-medium">
                  Commitment to Excellence
                </h3>
              </div>

              <div className="flex items-center p-5 my-5 sm:p-2">
                <div className="purple-bg-icon   w-[50px] box">
                  <i className=" fi-icon  ">
                    <AiOutlineMessage className=" " size={40} color="#4d41e1" />
                  </i>
                </div>
                <h3 className="text-lg font-medium">
                  Responsive Communication
                </h3>
              </div>
            </div>
            </Fade>
          </div>
          <Fade direction="up">
          <button className="px-20 py-5 bg-primaryBgColor text-white shadow-lg hover:bg-white hover:text-primaryBgColor w-fit rounded-lg"
          onClick={()=>{
            navigate( `/contact`,{state: {}});
          
          }}
          >
            Let's Build Great Product
          </button>
          </Fade>
        </div>
      </div>

      <Projects />

      <div className="h-max w-screen sm:bg-circle-pattern bg-no-repeat bg-left-center grid md:grid-cols-2 grid-cols-1">
        <div className="flex justify-evenly flex-col  py-[120px] md:px-20 px-10">
          <Fade direction="left">
          <h2 className="md:text-3xl font-medium pb-10 text-2xl">
            Here is some reson why most of the clients happy to{" "}
            <span className="text-primaryBgColor">Choice Me</span>
          </h2>
          </Fade>
          <Fade direction="right">
          <p className="text-lg font-light text-slate-700">
            Clients consistently choose and appreciate my services due to the
            combination of quality work, clear communication, and dedication to
            meeting their specific needs.
          </p>
          </Fade>
        </div>
        <div className="px-10">
          <div className="flex flex-col">
            <div className="py-5">
              <Fade direction="up">
              <div className="flex flex-row">
                <div className="purple-bg-icon  box1 pr-5 ">
                  <i className=" fi-icon1  ">
                    <MdWorkspacePremium className=" " size={40} color="#4d41e1" />
                  </i>
                </div>
                <div>
                  <h3 className="text-2xl font-medium pb-5">Quality Work</h3>
                  <p className="text-lg font-light text-slate-700">
                    I consistently deliver top-notch solutions tailored to meet
                    each client's unique requirements, ensuring they receive a
                    product that exceeds their expectations.
                  </p>
                </div>
              </div>
              </Fade>
            </div>
            <div className="py-5">
              <Fade direction="up">
              <div className="flex flex-row">
                <div className="purple-bg-icon  box1 pr-5 ">
                  <i className=" fi-icon1  ">
                    <AiOutlineMessage className=" " size={40} color="#4d41e1" />
                  </i>
                </div>
                <div>
                  <h3 className="text-2xl font-medium pb-5">
                    Clear Communication
                  </h3>
                  <p className="text-lg font-light text-slate-700">
                    Keeping clients informed and involved throughout the
                    project, I prioritize open and transparent communication to
                    build trust and understanding.
                  </p>
                </div>
              </div>
              </Fade>
            </div>
            <div className="py-5">
              <Fade direction="up">
              <div className="flex flex-row">
                <div className="purple-bg-icon  box1 pr-5 ">
                  <i className=" fi-icon1  ">
                    <IoMdHappy className=" " size={40} color="#4d41e1" />
                  </i>
                </div>
                <div>
                  <h3 className="text-2xl font-medium pb-5">
                    Dedication to Excellence
                  </h3>
                  <p className="text-lg font-light text-slate-700">
                    Committed to client satisfaction, I go above and beyond to
                    ensure a seamless and successful project delivery, earning
                    their trust and loyalty.
                  </p>
                </div>
              </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonial */}
      <Testimonials />

      {/* Contact Section */}

      {/* <div className="bg-indigo-50 min-h-[300px] my-[45px]  flex justify-center items-center flex-col">
        <h2 className="react-reveal text-center sm:text-4xl sm:line-height-9 text-3xl mt-20">
          Subscribe to my blog for the latest updates
        </h2>
        <div className="max-w-[600px] py-10 px-20 sm:px-5">
          <input
            className="shadow appearance-none border border-indigo-500 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Email Address"
          ></input>
        </div>
        <button className="px-12 py-3 mb-20 bg-primaryBgColor text-white shadow-lg hover:bg-white hover:text-primaryBgColor w-fit rounded-lg">
          Send Now
        </button>
      </div> */}

      {/* Footer Section */}

      <Footer />
    </>
  );
}
