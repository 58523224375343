
import { useState, useEffect } from "react";

import Carousel from "react-multi-carousel";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Fade } from "react-awesome-reveal";
export default function Testimonials() {
  const [testinomial, setTestinomial] = useState([]);

  useEffect(() => {
    fetch(
      "https://api.cosmicjs.com/v3/buckets/karannpatelcom-production/objects?pretty=true&query=%7B%22type%22:%22testimonials%22%7D&limit=10&read_key=wbU1U5RJVp7xt08XZRuXq9RXDBIPdvvR1AVJeZvOjXjP5lMEnA&depth=20&props=slug,title,metadata,id,type,status,created_by,created_at,published_at,modified_at,modified_by,publish_at,locale,thumbnail,content,"
    )
      .then((response) => response.json())
      .then((data) => {
        setTestinomial([]);
        return setTestinomial(data);
      })
      .catch((error) => {});
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="">
      <Fade direction="up">
      <h2 className="react-reveal mb-50 py-[50px] text-center sm:text-4xl sm:line-height-9 md:mb-[60px] mb-[45px] text-3xl">
        Let’s see testimonials to<br></br>
        what clients says
      </h2>
      </Fade>
      <div className=" w-screen flex flex-col  ">
     {
      testinomial.objects !== undefined ? <Fade direction="up" triggerOnce={true}> <Carousel
      responsive={responsive}
      infinite={true}
      swipeable={false}
      draggable={true}
      showDots={false}
    >
{testinomial.objects &&
  testinomial.objects.map((testinial) => (
      <div
          key={testinial.id}
          className=" flex flex-col bg-white min-h-[350px] shadow-lg m-10  rounded-[16px] justify-center items-center mx-[40px] testinomial "
      >
          <p className="text-md text-slate-600 text-center py-5 px-10">
          <ReactMarkdown 
               children=  {testinial.content}
               rehypePlugins={[rehypeRaw]}
            >
                
            </ReactMarkdown>
             
          </p>
          <h3 className="text-xl font-medium">
              {" "}
              {testinial.metadata.name}
          </h3>
          <h4 className="text-md text-slate-400 pb-5 text-center">
              {testinial.metadata.subheading}
          </h4>
          <img
              src={String(testinial.metadata.profileimg.imgix_url)}
              className="w-[60px] h-[60px] rounded-[30px]"
          />
      </div>
  ))}
  
</Carousel></Fade>  : <div>Loading...</div>
     }

      </div>
    </div>
  );
}
