import { Fade } from "react-awesome-reveal";
import Footer from "../commons/footer";
import Navbar from "../commons/navbar";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {useNavigate} from "react-router-dom";
export default function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  useEffect(() => {
    fetch(
      "https://api.cosmicjs.com/v3/buckets/karannpatelcom-production/objects?pretty=true&query=%7B%22type%22:%22blogs%22%7D&limit=10&read_key=wbU1U5RJVp7xt08XZRuXq9RXDBIPdvvR1AVJeZvOjXjP5lMEnA&depth=1&props=slug,title,metadata,id,type,status,created_by,created_at,published_at,modified_at,modified_by,publish_at,locale,thumbnail,content,"
    )
      .then((response) => response.json())
      .then((data) => {
        return setBlogs(data);
      })
      .catch((error) => {});
  }, []);
  return (
    <>
       <Helmet>
    <title>Latest Thoughts & Insights on Mobile & Web Development</title>
    <meta name="description" content="Explore our collection of articles for valuable insights, tips, and trends in mobile and web development. Stay updated with the latest industry news." />
    <meta
          name="keywords"
          content="Blog, Tips & Trends, Mobile Development, Web Development, No-Code Solutions, Digital Solutions, Karan N Patel, Ecommerce Solution"
        />
  </Helmet>
      <Navbar title={"Blogs"} />
      <div className="mt-[120px]">
        <Fade direction="up">
        <h2 className="text-4xl text-center">Latest Thoughts & Insights</h2>
        </Fade>
      </div>
      <Fade direction="up" triggerOnce={true}>
      <div className="min-h-[90vh]">
      <div className=" grid md:grid-flow-col md:auto-cols-1 gap-4 grid-col-1 py-10 items-center justify-center	">
        {blogs.objects &&
          blogs.objects.map((blog) => {
            return (
              <a
              onClick={()=>{
                navigate( `/blogs/${blog.slug}`,{state: {blog}});
               }}
              >
              <div class="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm min-w-[300px] mb-5 mx-[20px] ">
                  <img
                    class="rounded-t-lg"
                    src={blog.metadata.thumbnail.imgix_url}
                    alt=""
                  />
                <div class="p-5 flex flex-col justify-between h-auto">
                 
                    <h1 class="text-gray-900 font-bold text-2xl tracking-tight mb-2">
                      {blog.title}
                    </h1>
                 
                  <p class="font-normal text-gray-700 mb-3">
                    {blog.metadata.short_des}
                  </p>
                 <div className="text-center">
                 <a
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
                    onClick={()=>{
                        navigate( `/blogs/${blog.slug}`,{state: {blog}});
                       }}
                  >
                    Read more
                  </a>
                </div>
                </div>
              </div>
              </a>
             
            );
          })}
      </div>
      </div>
      </Fade>
      <Footer />
    </>
  );
}
