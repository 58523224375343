import Footer from "../commons/footer";
import Navbar from "../commons/navbar";
import { useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Helmet } from "react-helmet";
import { createBucketClient } from "@cosmicjs/sdk";
import { useState, useEffect } from "react";

export default function BlogDetails() {
  const location = useLocation();
  const [data, setData] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cosmic = createBucketClient({
          bucketSlug: "karannpatelcom-production",
          readKey: "wbU1U5RJVp7xt08XZRuXq9RXDBIPdvvR1AVJeZvOjXjP5lMEnA",
        });
        var res = await cosmic.objects
          .findOne({
            type: "blogs",
            slug: location.pathname.split("/")[
              location.pathname.split("/").length - 1
            ],
          })
          .props(
            "slug,title,metadata,id,type,status,created_by,created_at,published_at,modified_at,modified_by,publish_at,locale,thumbnail,content"
          )
          .depth(1);
        setData(res["object"]);
      } catch (error) {
      }
    };
    try {
      if (location.state.blog != undefined) {
        setData(location.state.blog);
      } else {
        fetchData();
      }
    } catch (e) {
      fetchData();
    }
  }, []);
  if (data == null) {
    return <></>;
  }
  if (data != null && data != undefined) {
    return (
      <>
        <Helmet>
          <title>{data.title}</title>
          <meta
            name="description"
            content="Explore our collection of articles for valuable insights, tips, and trends in mobile and web development. Stay updated with the latest industry news."
          />
          <meta
            name="keywords"
            content="Blog, Tips & Trends, Mobile Development, Web Development, No-Code Solutions, Digital Solutions, Karan N Patel, Ecommerce Solution"
          />
        </Helmet>
        <Navbar title={"Blogs"} />
        <div className="mt-[60px]  min-h-screen p-[30px] md:p-[60px] flex flex-col items-center">
          <h2 className="text-4xl text-center mb-10">{data.title}</h2>
          <div className="md:max-w-[80%] center">
            <ReactMarkdown
              children={data.content}
              rehypePlugins={[rehypeRaw]}
            ></ReactMarkdown>
          </div>
        </div>
        <Footer />
      </>
    );
  }
  return <></>;
}
