import Footer from "../commons/footer";
import Navbar from "../commons/navbar";
import Projects from "../home/components/projects";
import { Helmet } from "react-helmet";

export default function ProjectList(){
    return (<>
    <Helmet>
    <title>Portfolio of Customized Mobile & Web Development Projects</title>
    <meta name="description" content="Browse through our portfolio showcasing a diverse range of mobile and web development projects. See how we've helped clients achieve their digital goals." />
    <meta
          name="keywords"
          content="Portfolio, Client Success, Mobile Development, Web Development, No-Code Solutions, Digital Solutions, Karan N Patel, Ecommerce Solution"
        />
  </Helmet>
    <Navbar title={"Projects"}/>
    <Projects/>
    <Footer/>
    </>);
}