import React from "react";
import Navbar from "../commons/navbar";
import Footer from "../commons/footer";
import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

import { useEffect } from "react";
function Contact() {
  
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [isLoaded, setIsLoaded] = useState(false);
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoaded(true);
      const response = await fetch(`https://api.cosmicjs.com/v3/buckets/karannpatelcom-production/objects`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer WxBPr0jXE6d4nsNvjBD1S12Oh03p7SBfsRYeQEPEEcGAS9Gkhx`
        },
        body:JSON.stringify( {
          title: formData.name,
          type: 'contacts',
          metadata:{
            "message": formData.message,
            "email": formData.email,
            "reason":formData.name
          }
        })
      });

      if (response.ok) {

        // Handle successful form submission
        alert("Form submitted successfully");
        setFormData({
          name: "",
          email: "",
          message: ""
        });
        setIsLoaded(false);
      } else {
        // Handle form submission error
        console.error("Form submission failed");
        alert("Something went wrong while submitting the form");
        setIsLoaded(false);
      }
    } catch (error) {
      setIsLoaded(false);
      console.error("An error occurred while submitting the form", error);
    }
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value
    }));
  };

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  
  return (
    <>
    <Helmet>
    <title>Contact Karan N Patel for Custom Mobile & Web Development Solutions</title>
    <meta name="description" content="Ready to discuss your project or have questions about our services? Get in touch with Karan N Patel today for personalized digital solutions." />
    <meta
          name="keywords"
          content="Contact, Custom Solutions, Mobile Development, Web Development, No-Code Solutions, Digital Solutions, Karan N Patel, Ecommerce Solution"
        />
  </Helmet>
      <Navbar title={"Contact"}/>
      <div className="bg-white py-[65px]">
        <div className="relative text-white bg-primaryBgColor py-20">
          
          <div className=" tracking-wide inset-0  mx-auto">
            <div className="lg:p-0   px-4 h-full flex flex-col text-center items-center justify-center">
              <Fade direction="up">
              <p className="text-2xl pb-10 sm:text-base md:text-lg text-white lg:text-xl xl:text-2xl text-primary uppercase">
                Contact US
              </p>
              </Fade>
              <div className="border-b-2 border-primaryBgColor mb-10 ">
                <Fade direction="up">
                <p className="text-white md:text-6xl  mb-[25px] text-4xl mb-10">
                  Let's Get in Touch
                </p>
                </Fade>
              </div>
              <Fade direction="up">
              <p className="md:text-xl  mb-[50px] text-xl p-[2px] md:p-[0px] md:mx-[260px] md:mx-[60px] ">
              Whether you have a specific project in mind or just want to learn more about how I can help elevate your digital presence, I'd love to hear from you. Feel free to reach out using the contact information below or fill out the form to send a direct message.
              </p>
              </Fade>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto  max-w-fit mt-5 sm:px-6 lg:px-8">
          <div className="">
            <div className="p-12 px-10 bg-white ">
              <Fade direction="up">
              <h1 className="text-4xl font-regular pb-10">SEND MESSAGE NOW!</h1>
              </Fade>
              <p className="mb-4">
                {/* Feel free to reach out to us with any questions or concerns! */}
              </p>
              <Fade direction="up"damping={0.1}>
              <form>
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="text-xl font-light text-slate-700"
                  >
                    Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    required
                    value={formData.name}
                    onChange={(e)=>handleChange(e)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="text-xl font-light text-slate-700"
                  >
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    value={formData.email}
                    onChange={(e)=>handleChange(e)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="text-xl font-light text-slate-700"
                  >
                    Message:
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    required
                    value={formData.message}
                    onChange={(e)=>handleChange(e)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                {
                  isLoaded?<div className="flex justify-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primaryBgColor"></div>
                </div>:                <div className="flex justify-end">
                <button
                  // type="submit"
                  onClick={(e)=>handleSubmit(e)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outlin</div>e-none focus:shadow-outline"
                >
                  Submit
                </button>
              </div>
                }
              </form>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Contact;
