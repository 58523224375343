import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";

export function CarouselWithContent() {
  return (
    <>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
          <Fade direction="up" cascade damping={0.1}>
          <div className=" flex justify-center items-center text-center" >
            <div className="flex flex-col justify-center items-center">
              <h3 className=" text-white md:text-2xl leading-9 mb-[18px] text-2xl ">
                KARAN N PATEL
              </h3>
              <h1 className="text-white md:text-6xl  mb-[25px] text-4xl">
              Elevate Your Digital Presence
              </h1>
              <p className="md:text-xl text-white mb-[50px] text-xl p-[20px] md:p-[0px]">
              In today's fast-paced digital world, your online presence is more crucial than ever. Whether you're a budding entrepreneur, a passionate artist, or a forward-thinking organization, I empower you to stand out.
              </p>
              <a className="btn btn-primary btn-large hero-btn" >
                <Link to="/contact">Start Your Journey<i className="icofont-long-arrow-right"></i></Link>
              </a>
            </div>
          </div>
          </Fade>
        </SwiperSlide>
       
      </Swiper>
    </>
  );
}
