import React from "react";
import Navbar from "../commons/navbar";
import Footer from "../commons/footer";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
const samplePng = require("../../assets/devImage.jpg");

function About() {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <div>
        <Helmet>
    <title>About Karan N Patel | Expertise in Mobile & Web Development</title>
    <meta name="description" content="Learn more about Karan N Patel, a dedicated mobile and web developer with a passion for crafting innovative digital solutions tailored to client needs." />
    <meta
          name="keywords"
          content="Mobile Development, Web Development, No-Code Solutions, Digital Solutions, Karan N Patel, Ecommerce Solution"
        />
  </Helmet>
      <Navbar title={"About"}/>
      <div className="min-h-screen relative">
        {/* create a hero component for about us with tailwind */}
        <div className="grid pt-[60px]  md:grid-cols-2 sm:grid-cols-1">
          <div className="flex flex-col justify-center bg-indigo-50 min-h-[80vh] px-[40px] ">
            <Fade direction="left">
            <h2 className="text-4xl font-regular pb-10">
            Crafting Digital Solutions with Passion & Precision
            </h2>
            </Fade>
            <Fade direction="left">
            <p className="text-xl font-light text-slate-700">
            I'm Karan N Patel, a dedicated mobile and web developer with a passion for transforming ideas into innovative digital solutions. Specializing in Flutter, MERN stack, Shopify, FlutterFlow, and Wordpress technologies, I bring a wealth of expertise to every project I undertake. Committed to excellence, I strive to deliver tailored development services that not only meet but exceed your expectations. Let's collaborate and create something extraordinary together!
            </p>
            </Fade>
            <Fade direction="left">
            <button className="px-20 py-5 bg-primaryBgColor text-white shadow-lg hover:bg-white hover:text-primaryBgColor w-fit rounded-lg mt-10">
              Contact Now
            </button>
            </Fade>
          </div>
          <div>
            <Fade direction="right" >
            <img
              src={String(samplePng)}
              alt="sample"
              className="object-cover w-full h-full"
            />
            </Fade>
          </div>
         
        </div>
        <div className="grid gird-cols-1 w-screen">
            <div className="py-[100px] text-center md:px-[30vw] px-5 ">
            <Fade direction="up" >
              <h2 className="text-4xl font-medium">WHO I AM</h2>
              </Fade>
              <Fade direction="up"damping={0.1} >
              <p className="text-xl font-light text-slate-700 py-10">
              With a focus on crafting personalized digital solutions, I offer expertise in mobile and web development paired with a passion for innovation. I've been delivering high-quality IT services to clients across the globe since 2019
              </p>
              </Fade>
            </div>
          </div>
          <div className="grid gird-cols-1">
            <div className="py-[100px]   text-center md:px-[30vw] px-5 ">
            <Fade direction="up" >
              <h2 className="text-4xl font-medium">WHAT I DO</h2>
              </Fade>
              <Fade direction="up"damping={0.1} >
              <p className="text-xl font-light text-slate-700 py-10">
              I specialize in crafting high-quality mobile and web applications using technologies like Flutter, MERN stack, Shopify, FlutterFlow, and Wordpress. Whether you're a startup looking to make a mark or an established business aiming to innovate, I provide tailored development services that align perfectly with your goals.
              </p>
              </Fade>
            </div>
          </div>
          <div className="grid gird-cols-1">
            <div className="py-[100px]   text-center md:px-[30vw] px-5">
            <Fade direction="up" >
              <h2 className="text-4xl font-medium">HOW I DO</h2>
              </Fade>
              <Fade direction="up"damping={0.1} >
              <p className="text-xl font-light text-slate-700 py-10">
              I prioritize understanding your vision and objectives, ensuring clear and open communication throughout the development process. With an agile approach, I adapt quickly to changing project requirements, delivering flexible and responsive solutions that exceed your expectations. Partner with me for a seamless digital journey from concept to launch.
              </p>
              </Fade>
            </div>
          </div>

            {/* Testimonial */}
      {/* <div className="my-[120px] mx-[60px]">
        <h2 className="react-reveal mb-50 text-center sm:text-4xl sm:line-height-9 md:mb-[60px] mb-[45px] text-3xl">
          Our <span className="text-primaryBgColor font-medium">
          Team</span>
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
        <div className=" flex flex-col bg-white shadow-2xl p-2  rounded-[16px] justify-center items-center m-[10px]">
        <img
            src={String(samplePng)}
            className="min-w-[260px]  rounded-[12px]"
          />
          <h3 className="text-xl font-medium">Texs Sajio</h3>
          <h4 className="text-md text-slate-400 pb-5">CEO of DAMD</h4>
          
        </div><div className=" flex flex-col bg-white shadow-2xl p-2  rounded-[16px] justify-center items-center m-[10px]">
        <img
            src={String(samplePng)}
            className="min-w-[260px]  rounded-[12px]"
          />
          <h3 className="text-xl font-medium">Texs Sajio</h3>
          <h4 className="text-md text-slate-400 pb-5">CEO of DAMD</h4>
          
        </div><div className=" flex flex-col bg-white shadow-2xl p-2  rounded-[16px] justify-center items-center m-[10px]">
        <img
            src={String(samplePng)}
            className="min-w-[260px]  rounded-[12px]"
          />
          <h3 className="text-xl font-medium">Texs Sajio</h3>
          <h4 className="text-md text-slate-400 pb-5">CEO of DAMD</h4>
          
        </div><div className=" flex flex-col bg-white shadow-2xl p-2  rounded-[16px] justify-center items-center m-[10px]">
        <img
            src={String(samplePng)}
            className="min-w-[260px]  rounded-[12px]"
          />
          <h3 className="text-xl font-medium">Texs Sajio</h3>
          <h4 className="text-md text-slate-400 pb-5">CEO of DAMD</h4>
          
        </div><div className=" flex flex-col bg-white shadow-2xl p-2  rounded-[16px] justify-center items-center m-[10px]">
        <img
            src={String(samplePng)}
            className="min-w-[260px]  rounded-[12px]"
          />
          <h3 className="text-xl font-medium">Texs Sajio</h3>
          <h4 className="text-md text-slate-400 pb-5">CEO of DAMD</h4>
          
        </div> <div className=" flex flex-col bg-white shadow-2xl p-2  rounded-[16px] justify-center items-center m-[10px]">
        <img
            src={String(samplePng)}
            className="min-w-[260px]  rounded-[12px]"
          />
          <h3 className="text-xl font-medium">Texs Sajio</h3>
          <h4 className="text-md text-slate-400 pb-5">CEO of DAMD</h4>
          
        </div>
        </div>
      </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default About;
