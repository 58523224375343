import About from "./pages/about/about";
import Home from "./pages/home/home";
import * as React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Service from "./pages/service/service";
import Contact from "./pages/contact/contact";
import ProjectDetail from "./pages/project/project";
import ProjectList from "./pages/project/projects";
import Blogs from "./pages/blog/blogs";
import BlogDetails from "./pages/blog/blogDetails";

import {Helmet} from 'react-helmet'

import { useLocation } from 'react-router-dom';
// export default function App() {
//   const router = createBrowserRouter([
//     {
//       path: "/",
//       element: <Home/>,
//     },
//     {
//       path: "/about",
//       element: <About/>,
//     },
//     {
//       path: "/service",
//       element: <Service/>
//     },
//     {
//       path:"/contact",
//       element:<Contact/>
//     },
//     {
//       path:"/blogs",
//       element:<Blogs/>
//     },
//     {
//       path:"/blogs/*",
//       element:<BlogDetails/>
//     },
//     {
//       path: "/projects",
//       element: <ProjectList/>,
//     },
//     {
//       path: "/project/*",
//       element: <ProjectDetail/>,

//     }
//   ]);
//   ReactDOM.createRoot(document.getElementById("root")).render(
//     <React.StrictMode>
//       <RouterProvider router={router} />
//     </React.StrictMode>
//   );
// }

export default function App() {



  return (
    <div>
      
      <Helmet>
    <title>Karan N Patel | Expert Mobile & Web Development Services</title>
    <meta name="description" content="Specializing in mobile and web development, I offer tailored digital solutions to help clients achieve their business goals. Contact me today!" />
    <meta
          name="keywords"
          content="Mobile Development, Web Development, No-Code Solutions, Digital Solutions, Karan N Patel, Ecommerce Solution"
        />
  </Helmet>
      <Router  >
      <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="service" element={<Service />} />
          <Route path="contact" element={<Contact />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="projects" element={<ProjectList />} />
          <Route path="project/*" element={<ProjectDetail />} />
          <Route path="blogs/*" element={<BlogDetails />} />
          </Routes>
      </Router>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
