
import diamond from "../../assets/dimond.svg";
import bannerDots from "../../assets/banner-dots.svg";
import bannerDots2 from "../../assets/banner-dots-2.svg";
import curlyShape from "../../assets/curly-shape.svg";

import './common.css';
import { Link } from "react-router-dom";

import { useNavigate, } from 'react-router-dom';
const logo = require('../../assets/whiteLogo.png');
function Footer(){
    const navigate = useNavigate();
 return (
    <footer className=" relative sm:bg-hero-pattern bg-cover bg-center bg-primaryBgColor footerBg pt-[50px]">
          <div className="banner-shapes footerBg">
          <img
            src={diamond}
            alt="banner-diamond-shape"
            className="banner-diamond-shape"
          ></img>
          <img
            src={bannerDots}
            alt="banner-diamond-shape"
            className="banner-square-dots-1"
          ></img>
          <img
            src={bannerDots2}
            alt="banner-diamond-shape"
            className="banner-square-dots-2"
          ></img>
          <img
            src={curlyShape}
            alt="banner-diamond-shape"
            className="banner-curly-shape"
          ></img>
        </div>
        <div className="mx-auto absolute w-full  p-4 py-6 lg:py-8 footerBg " style={{zIndex:"-20"}}>
            <div className="md:flex md:justify-between">
              <div className="mb-6 md:mb-0 max-w-[400px]" >
              <a href="https://karannpatel.com/" className="flex items-center">
            <img
               src={String(logo)}
              className="h-8 mr-3"
              alt="Karannpatel Logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
            Karan N Patel
            </span>
          </a>
          <p className="md:text-lg text-white mb-[50px] text-lg p-[20px] md:p-[0px] mt-5">
              In today's fast-paced digital world, your online presence is more crucial than ever. Whether you're a budding entrepreneur, a passionate artist, or a forward-thinking organization, I empower you to stand out.
              </p>
                  {/* <a href="https://flowbite.com/" className="flex items-center">
                      <img src="https://flowbite.com/docs/images/logo.svg" className="h-8 mr-3" alt="FlowBite Logo" />
                      <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span>
                  </a> */}
              </div>
              <div className="grid grid-cols-3 gap-4 sm:gap-6 sm:grid-cols-3">
                  
                  <div>
                      <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">Follow us</h2>
                      <ul className="text-gray-500 dark:text-gray-400 font-medium">
                          <li className="mb-4">
                              <a href="https://github.com/karanpatel2329" className="hover:underline text-slate-300">Github</a>
                          </li>
                          <li className="mb-4">
                              <a href="https://linkedin.com/in/karanpatel2329" className="hover:underline text-slate-300">LinkedIn</a>
                          </li>
                          <li className="mb-4">
                              <a href="https://twitter.com/karanpa62994928" className="hover:underline text-slate-300">Twitter</a>
                          </li>
                          
                      </ul>
                  </div>
                  <div>
                      <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">Quick link</h2>
                      <ul className="text-gray-500 dark:text-gray-400 font-medium">
                          <li className="mb-4">
                              <a className="hover:underline text-slate-300"><Link to="/projects">Projects</Link></a>
                          </li>
                          <li  className="mb-4">
                              <a  className="hover:underline text-slate-300"><Link to="/blogs"> Blogs</Link></a>
                          </li>
                          <li  className="mb-4">
                              <a className="hover:underline text-slate-300"><Link to="/service"> Service</Link></a>
                          </li>
                          <li  className="mb-4">
                              <a className="hover:underline text-slate-300"><Link to="/contact"> Contact</Link></a>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
     
        </div>
        
      
    </footer>
    );
}

export default Footer;