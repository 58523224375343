import { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { useNavigate, Link } from "react-router-dom";
// Fade
export default function Projects() {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetch(
      "https://api.cosmicjs.com/v3/buckets/karannpatelcom-production/objects?pretty=true&query=%7B%22type%22:%22projects%22%7D&limit=10&read_key=wbU1U5RJVp7xt08XZRuXq9RXDBIPdvvR1AVJeZvOjXjP5lMEnA&depth=1&props=slug,title,metadata,id,type,status,created_by,created_at,published_at,modified_at,modified_by,publish_at,locale,thumbnail,content,"
    )
      .then((response) => response.json())
      .then((data) => {
        return setProjects(data);
      })
      .catch((error) => {});
  }, []);
  return (
    <div className="h-max py-[120px]">
      <Fade direction="up" >
      <h2 className="text-4xl text-center">
        Success Stories Delivered for Previous Clients
      </h2>
      </Fade>
      <div className="grid pt-[60px] lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 place-content-center">
        {projects.objects &&
          projects.objects.map((project) => (
            <Fade  key={project.id} direction="up" triggerOnce={true}>
              <div className="p-5">
              <img
                className="h-[400px] w-auto ml-auto mr-auto"
                src={project.metadata.image.imgix_url}
              />
              {project.metadata.thumbnail}
              <h2 className="text-xl font-medium py-2 text-center py-5">
                {project.title}
              </h2>
              <p className="text-md font-light text-slate-400 py-2">
                {project.metadata.description}
              </p>
              <center>
                {" "}
                <button
                  className="px-12 py-3 align-center mb-20 bg-primaryBgColor text-white shadow-lg hover:bg-white hover:text-primaryBgColor w-fit rounded-lg"
                  onClick={() => {
                    navigate(`/project/${project.slug}`, {
                      state: { project },
                    });
                  }}
                >
                  View Project
                </button>
              </center>
            </div>
            </Fade>
          ))}
      </div>
    </div>
  );
}
