import React from "react";
import Navbar from "../commons/navbar";
import {AiOutlineArrowRight} from 'react-icons/ai'
import Projects from "../home/components/projects";
import Footer from "../commons/footer";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

import { useState, useEffect } from "react";
const mobileSvg = require("../../assets/mobileService.png");

const webService = require("../../assets/webService.png");

const nocodeService = require("../../assets/nocodeService.png");
const ecommerceService = require("../../assets/ecommerceService.png");
const supportService = require("../../assets/supportService.png");
const consulatingService = require("../../assets/consulatingService.png");
const Service = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <div>
      <Helmet>
    <title>Customized Mobile & Web Development Services by Karan N Patel</title>
    <meta name="description" content="Discover a comprehensive range of specialized services offered by Karan N Patel, including mobile app development, web design, e-commerce solutions, and more." />
    <meta
          name="keywords"
          content="Services, Custom Services, Mobile Development, Web Development, No-Code Solutions, Digital Solutions, Karan N Patel, E-commerce Solution"
        />
  </Helmet>
      <Navbar title={"Service"}/>
      <div className=" min-h-screen md:mx-[160px] mx-[20px]  ">
      <div className="mt-20 col-6">
      <Fade direction="up">
        <h1 className="text-center text-4xl font-medium underline decoration-primaryColor underline-offset-8">
          My Services
        </h1>
        </Fade> 
        <Fade direction="up" cascade damping={0.1} triggerOnce={true}>
        <p className="mt-10 px-10 md:mx-10 text-center text-lg">
        I offer a comprehensive suite of services tailored to help clients reach their digital objectives. I provide specialized solutions designed to align with your unique requirements, leveraging my expertise to deliver results that exceed expectations.
        </p>
        </Fade>

       <Fade cascade direction="up"  triggerOnce={true}>
         {/* Services List */}
         <div className="grid md:grid-cols-3 sm:grid-cols-1 text-center ">
          <div className="flex m-10 items-center flex-col">
            <img
              src={String(mobileSvg)}
              alt="sample"
              className="object-cover w-[200px] h-[200px]"
            />
            <h2 className="text-lg font-medium py-3">Mobile App Development</h2>
            <p className="text-md px-5 py-2">Crafting intuitive and feature-rich mobile applications tailored to your business needs, ensuring a seamless user experience across all platforms.</p>
            <div className="flex items-center py-3">
                {/* <p className="text-lg font-medium text-primaryBgColor pr-2">Know More  </p> */}
                {/* <AiOutlineA rrowRight size={25} className="text-primaryBgColor"/> */}
            </div>
          </div>
          <div className="flex m-10 items-center flex-col">
            <img
              src={String(webService)}
              alt="sample"
              className="object-cover w-[200px] h-[200px]"
            />
            <h2 className="text-lg font-medium py-3">Web Development</h2>
            <p className="text-md px-5 py-2">Designing and developing responsive and user-friendly websites, leveraging the latest technologies to enhance your online presence and engage your audience.</p>
            <div className="flex items-center py-3">
                {/* <p className="text-lg font-medium text-primaryBgColor pr-2">Know More  </p> */}
                {/* <AiOutlineArrowRight size={25} className="text-primaryBgColor"/> */}
            </div>
          </div>
          <div className="flex m-10 items-center flex-col">
            <img
              src={String(ecommerceService)}
              alt="sample"
              className="object-cover w-[200px] h-[200px]"
            />
            <h2 className="text-lg font-medium py-3">E-commerce Solutions</h2>
            <p className="text-md px-5 py-2">Building robust and scalable e-commerce platforms using Shopify and other platforms, optimizing for sales and user experience to drive business growth.</p>
            <div className="flex items-center py-3">
                {/* <p className="text-lg font-medium text-primaryBgColor pr-2">Know More  </p> */}
                {/* <AiOutlineArrowRight size={25} className="text-primaryBgColor"/> */}
            </div>
          </div>
          <div className="flex m-10 items-center flex-col">
            <img
              src={String(nocodeService)}
              alt="sample"
              className="object-cover w-[200px] h-[200px]"
            />
            <h2 className="text-lg font-medium py-3">NOCode Development</h2>
            <p className="text-md px-5 py-2">Providing rapid prototyping and deployment solutions using NOCode platforms, enabling quick and cost-effective development without compromising on quality.</p>
            <div className="flex items-center py-3">
                {/* <p className="text-lg font-medium text-primaryBgColor pr-2">Know More  </p> */}
                {/* <AiOutlineArrowRight size={25} className="text-primaryBgColor"/> */}
            </div>
          </div>
          <div className="flex m-10 items-center flex-col">
            <img
              src={String(consulatingService)}
              alt="sample"
              className="object-cover w-[200px] h-[200px]"
            />
            <h2 className="text-lg font-medium py-3">Consulting & Strategy</h2>
            <p className="text-md px-5 py-2">Offering expert advice and strategic planning to help you leverage technology effectively, aligning with your business goals and maximizing ROI.</p>
            <div className="flex items-center py-3">
                {/* <p className="text-lg font-medium text-primaryBgColor pr-2">Know More  </p> */}
                {/* <AiOutlineArrowRight size={25} className="text-primaryBgColor"/> */}
            </div>
          </div>
          <div className="flex m-10 items-center flex-col">
            <img
              src={String(supportService)}
              alt="sample"
              className="object-cover w-[200px] h-[200px]"
            />
            <h2 className="text-lg font-medium py-3">Maintenance & Support</h2>
            <p className="text-md px-5 py-2">Ensuring smooth operation and optimal performance of your digital solutions with comprehensive maintenance and dedicated support services.</p>
            <div className="flex items-center py-3">
                {/* <p className="text-lg font-medium text-primaryBgColor pr-2">Know More  </p> */}
                {/* <AiOutlineArrowRight size={25} className="text-primaryBgColor"/> */}
            </div>
          </div>
        </div>
        </Fade>
        <Fade cascade direction="up"  triggerOnce={true}>
        {/* Progress List */}
        <div className="my-10">
            <h2 className="text-center text-2xl font-medium">Roadmap To Your Success</h2>
            <div className="grid md:grid-cols-3 sm:grid-cols-1 text-center ">
                <div className="m-10 rounded-xl border-2 border-primaryBgColor">
                    <h2 className="text-4xl py-5 font-medium text-primaryBgColor">01</h2>
                    <h3 className="text-xl py-2 font-medium">Planning</h3>
                    <p className="text-md px-5  mb-10 ">We meticulously plan our approach, creating a tailored roadmap that outlines the steps towards achieving your digital goals. By aligning our strategies with your objectives, we set the foundation for a successful project.</p>
                </div>
                <div className="m-10 rounded-xl border-2 border-primaryBgColor">
                    <h2 className="text-4xl py-5 font-medium text-primaryBgColor">02</h2>
                    <h3 className="text-xl py-2 font-medium">UI/UX Design</h3>
                    <p className="text-md px-5  mb-10 ">Prioritizing user-centric design, we craft intuitive and engaging interfaces that enhance user experience and drive engagement. Our UI/UX design services ensure your digital solutions are both visually appealing and functional.</p>
                </div>
                <div className="m-10 rounded-xl border-2 border-primaryBgColor">
                    <h2 className="text-4xl py-5 font-medium text-primaryBgColor">03</h2>
                    <h3 className="text-xl py-2 font-medium">Development</h3>
                    <p className="text-md px-5  mb-10 ">With a focus on quality and innovation, we embark on the development phase. Leveraging cutting-edge technologies and best practices, we bring your ideas to life, crafting digital solutions that resonate with your audience.</p>
                </div>
                <div className="m-10 rounded-xl border-2 border-primaryBgColor">
                    <h2 className="text-4xl py-5 font-medium text-primaryBgColor">04</h2>
                    <h3 className="text-xl py-2 font-medium">Testing & Quality Assurance</h3>
                    <p className="text-md px-5  mb-10 ">Prioritizing performance and reliability, we conduct rigorous testing to ensure your digital solutions are free from errors and meet the highest quality standards. Our meticulous QA process guarantees a seamless user experience.</p>
                </div>
                <div className="m-10 rounded-xl border-2 border-primaryBgColor">
                    <h2 className="text-4xl py-5 font-medium text-primaryBgColor">05</h2>
                    <h3 className="text-xl py-2 font-medium">Deployment</h3>
                    <p className="text-md px-5  mb-10 ">Upon successful testing, we deploy your digital solutions to the live environment, ensuring a smooth transition and minimal downtime. We handle all aspects of deployment, ensuring your project goes live without a hitch.</p>
                </div>
                <div className="m-10 rounded-xl border-2 border-primaryBgColor">
                    <h2 className="text-4xl py-5 font-medium text-primaryBgColor">06</h2>
                    <h3 className="text-xl py-2 font-medium">Maintenance & Support</h3>
                    <p className="text-md px-5  mb-10 ">Our commitment to your success doesn't end at deployment. We offer ongoing maintenance and dedicated support services to ensure your digital solutions continue to perform optimally and evolve with your business needs.</p>
                </div>
                
            </div>
            
        </div>
        </Fade>

        {/* Why to choose us */}
        <div>
        <Fade cascade direction="up"  triggerOnce={true}>
        <h2 className="text-center text-2xl font-medium">Why to choose me</h2>
        </Fade>
        <Fade cascade direction="up"  triggerOnce={true}>
        <p className="mt-10 px-10 md:mx-10 text-center text-lg">Clients consistently choose and appreciate my services due to the combination of quality work, clear communication, and dedication to meeting their specific needs.</p>
        </Fade>
        </div>
        {/* Previous work */}
      <Projects/>
      
   
      </div>
      </div>
      <Footer/>
      {/* Your service component code goes here */}
    </div>
  );
};

export default Service;
